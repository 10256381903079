import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


import mwindow from "../images/wFrames/metal-window-frames.png";
import alwindow from "../images/wFrames/aluminium-window-frames.png";
import mdoor from "../images/wFrames/metal-door-frame.png";
import aldoor from "../images/wFrames/aluminium-door-frame.png";


function FramesPage() {
    return (
      <Layout>
        <SEO
          title="Door and Window Frames"
          keywords={[`Window frames`, `Door frames`, `Zimbabwe`, `doors windows`]}
        />
  
       
            <div class="rounded overflow-hidden ">
              <img src={mwindow}  alt="Sunset in the mountains"/>
               
               <div class="font-bold text-xl mb-2">Metal Window Frames</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               <img src={alwindow}  alt="Sunset in the mountains"/>
             
               <div class="font-bold text-xl mb-2">Aluminium Window Frames</div>
               <p class="text-gray-700 text-base">
               
               </p>
            
             
            
          
            <div class="rounded overflow-hidden ">
              <img src={mdoor}  alt="Sunset in the mountains"/>
               
               <div class="font-bold text-xl mb-2">Metal Door Frames</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             <img src={aldoor}  alt="Sunset in the mountains"/>
              
               <div class="font-bold text-xl mb-2">Aluminium Door Frames</div>
               <p class="text-gray-700 text-base">
              
               </p>
             

             


            
           
          
               <h4 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Call or WhatsApp: +263 774 500 868 or +263 773 386 695
        </h4>
             
            
         
            
         
        
      </Layout>
    );
  }
  
  export default FramesPage;
  